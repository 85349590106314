.MuiBackdrop-root {
  opacity: 0 !important;
}
.text-modal {
  color: #333333 !important;
  font-size: 15px !important;
  font-family: Minion-Rel !important;
  line-height: 32px !important;
  letter-spacing: 0.54px !important;
  text-align: center !important;
}
.text-modal a {
  color: #333333 !important;
}
.close-icon > div {
  cursor: pointer;
  margin-left: auto;
  margin-right: 0;
  height: 30px !important;
  width: 30px !important;
}
.close-icon img {
  height: 30px !important;
  width: 30px !important;
  filter: invert(0.9);
}
