/* Inicio Fonts */
@font-face {
  font-family: Roboto-Bold;
  src: url(./assets/Fonts/Roboto-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: Roboto-Light;
  src: url(./assets/Fonts/Roboto-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family: Roboto-Medium;
  src: url(./assets/Fonts/roboto-medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: Roboto-Rel;
  src: url(./assets/Fonts/Roboto-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: Trajan-Bold;
  src: url(./assets/Fonts/TrajanPro-Bold.otf);
  font-display: swap;
}
@font-face {
  font-family: Trajan-Rel;
  src: url(./assets/Fonts/TrajanPro-Regular.otf);
  font-display: swap;
}
@font-face {
  font-family: Minion-SemiBold;
  src: url(./assets/Fonts/minion-pro-semibold.otf);
  font-display: swap;
}
@font-face {
  font-family: Basker-Italic;
  src: url(./assets/Fonts/Baskerville-Italic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Basker-Rel;
  src: url(./assets/Fonts/Baskerville-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: Minion-Italic;
  src: url(./assets/Fonts/minion-pro-italic.otf);
  font-display: swap;
}
@font-face {
  font-family: Minion-Rel;
  src: url(./assets/Fonts/MinionPro-Regular.otf);
  font-display: swap;
}
/* Fin Fonts */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  width: 100% !important;
  height: 100% !important;
}
a {
  text-decoration: none;
}
/* Fonts */
.react-carousel-custom {
  margin: 0 auto;
}
.react-multi-carousel-dot {
  display: flex;
  align-items: center;
}
.react-multi-carousel-dot button {
  width: 14px !important;
  height: 14px !important;
  border-width: 0px !important;
  background: rgb(91, 68, 25) !important;
}
.react-multi-carousel-dot--active button {
  background: transparent !important;
  border-color: rgb(91, 68, 25) !important;
  border-width: 2px !important;
  padding: 2px !important;
  width: 16px !important;
  height: 16px !important;
}

.slick-slider.slick-initialized {
  padding-left: 50px;
  padding-right: 50px;
  /* overflow-x: hidden; */
}
.slick-dots {
  position: inherit !important;
  /* width: 399px; */
}

.slick-dots li button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li button {
  border-radius: 50%;
  width: 14px !important;
  height: 14px !important;
  border-width: 0px !important;
  background: #a37644 !important;
}
.slick-dots li.slick-active button {
  background: transparent !important;
  /* border-color: #d59570 !important;
  border-width: 2px !important; */
  border: 2px solid #a37644 !important;
  padding: 2px !important;
  width: 16px !important;
  height: 16px !important;
}

/* backdrop video */
.MuiDialog-root .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.9);
}

@media (max-width: 576px) {
  .slick-dots {
    bottom: -5px;
  }
}
.slick-slider.slick-initialized {
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 576px) {
  .slick-slider.slick-initialized {
    padding-left: 100px;
    padding-right: 100px;
  }
}
#slider-padre .slick-track {
  margin: auto;
}
.icMenu {
  width: 50px;
  height: 50px;
  position: relative;
  background-color: transparent;
  /* top: 35px;
  right: 35px; */
  z-index: 10000;
  cursor: pointer;
  -moz-transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease 0s;
  -ms-transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
}
.icMenu .a {
  height: 1px;
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  background-color: #fff;
  -moz-transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease 0s;
  -ms-transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
}
.icMenu .b {
  height: 1px;
  position: absolute;
  top: 25px;
  left: 15px;
  right: 15px;
  background-color: #fff;
  -moz-transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease 0s;
  -ms-transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
}
.icMenu .c {
  height: 1px;
  position: absolute;
  top: 35px;
  left: 15px;
  right: 15px;
  background-color: #fff;
  -moz-transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease 0s;
  -ms-transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
}
#boton-menu-open:hover .icMenu .a,
.icMenu:hover .a {
  left: 15px;
}
#boton-menu-open:hover .icMenu .b,
.icMenu:hover .b {
  left: 25px;
}
#boton-menu-open:hover .icMenu .c,
.icMenu:hover .c {
  left: 20px;
}

/* slide vertical */
#slide-stick-vertical .slick-slider.slick-initialized {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

/* css maps api */
div.gm-style.gm-style-iw-t {
  top: -30px !important;
}
.gm-style .gm-style-iw-c {
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 5%);
}
.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary {
  text-decoration: none !important;
}
